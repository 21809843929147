<template>
  <div class="content">
    <div class="content-box">
      <div class="info">
        <h2 class="title">Icons set</h2>
        <div class="message">
          partagez vcs places libres avec taus ves callabarateurs grace a notre
          application de parking collabaratif.
        </div>
      </div>

      <div class="win-box">
        <div class="win-grid">
          <div
            v-for="(item, i) in subjectList"
            :id="i"
            :key="i"
            class="win-btn"
            @click="toQuestionBank(item)"
          >
            <div v-html="item.icon" />
            <span class="name">{{ item.subject }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const offset = 69
const angles = []
let nearBy = []
for (let i = 0; i <= 360; i += 45) {
  angles.push((i * Math.PI) / 180)
}
export default {
  data: () => ({
    subjectList: []
  }),
  mounted() {
    this.hover()
    this.getSubjectList()
  },
  methods: {
    toQuestionBank(item) {
      if (item.id) {
        this.$router.push({ name: 'Bank', query: { id: item.id }})
      }
    },
    async getSubjectList() {
      const { results = [] } = await this.$store.dispatch(
        'subject/getSubjectList'
      )
      if (results) {
        for (const item of results) {
          item.icon = '<i class="iconfont">&#xe609;</i>'
        }
        this.subjectList = results
      }
    },

    hover() {
      document.querySelectorAll('.win-btn').forEach((b) => {
        b.onmouseleave = (e) => {
          e.target.style.background = '#fff'
          e.target.style.borderImage = null
          e.target.border = '1px solid transparent'
        }

        b.onmouseenter = (e) => {
          this.clearNearBy()
        }

        b.addEventListener('mousemove', (e) => {
          e.target.border = '1px solid transparent'
          const rect = e.target.getBoundingClientRect()
          const x = e.clientX - rect.left // x position within the element.
          const y = e.clientY - rect.top // y position within the element.
          e.target.style.background = `radial-gradient(circle at ${x}px ${y}px , rgba(255,255,255,0.25),rgba(255,255,255,1) )`
          e.target.style.borderImage = `radial-gradient(20% 65% at ${x}px ${y}px ,rgba(0, 183, 255,0.7),rgba(0, 183, 255,0.7),rgba(0, 183, 255,0.1) ) 9 / 1px / 0px stretch `
        })
      })

      const body = document.querySelector('.win-grid')

      body.addEventListener('mousemove', (e) => {
        const x = e.x // x position within the element.
        const y = e.y // y position within the element.

        this.clearNearBy()
        nearBy = angles.reduce((acc, rad, i, arr) => {
          const cx = Math.floor(x + Math.cos(rad) * offset)
          const cy = Math.floor(y + Math.sin(rad) * offset)
          const element = document.elementFromPoint(cx, cy)

          if (element !== null) {
            if (
              element.className === 'win-btn' &&
              acc.findIndex((ae) => ae.id === element.id) < 0
            ) {
              const brect = element.getBoundingClientRect()
              const bx = x - brect.left // x position within the element.
              const by = y - brect.top // y position within the element.
              if (!element.style.borderImage) {
                element.style.borderImage = `radial-gradient(${offset * 2}px ${
                  offset * 2
                }px at ${bx}px ${by}px ,rgba(0, 183, 255 ,0.7),rgba(0, 183, 255 ,0.1),transparent ) 9 / 1px / 0px stretch `
              }
              return [...acc, element]
            }
          }
          return acc
        }, [])
      })

      body.onmouseleave = (e) => {
        this.clearNearBy()
      }
    },

    // event 清空鼠标附近元素数组 nearBy
    clearNearBy() {
      nearBy
        .splice(0, nearBy.length)
        .forEach((e) => (e.style.borderImage = null))
    }
  }
}
</script>

<style scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
.content .content-box {
  display: flex;
  align-items: center;
  width: 700px;
  height: 400px;
}
.content .content-box {
  display: flex;
  align-items: center;
  width: 700px;
  height: 400px;
}
.content .content-box .info {
  width: 300px;
  text-align: left;
}
.content .content-box .info .title {
  font-size: 30px;
  color: black;
}
.content .content-box .icon-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 400px;
}
.content .content-box .icon-box div img {
  width: 100px;
  margin: 15px 15px;
}

.win-btn >>> .iconfont {
  font-size: 48px;
  color: rgb(0, 153, 255);
}
.win-box {
  background-color: #fff;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.win-grid {
  display: grid;
  letter-spacing: 2px;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  grid-gap: 1rem;
  padding: 1rem;
  /* border: 1px solid rgb(0, 183, 255); */
  text-align: center;
  border-radius: 10px;
}
.win-btn {
  padding: 2rem 2rem;
  text-align: center;
  border: none;
  border-radius: 0px;
  border: 1px solid transparent;
}
.win-btn .name {
  display: inline-block;
  width: 65px;
  color: skyblue;
  overflow: hidden;
  font-size: 14px;
}
button:focus {
  outline: none;
}
</style>
